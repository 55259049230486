<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="generadoresExpedientes"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="openTiposGeneradores"
                v-if="canGoToTiposGeneradores"
                class="no-upper-case ml-4"
                color="light-grey"
                elevation="1"
              >
                Tipos de generadores
              </v-btn>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right"> Nuevo </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="openModal(item.genId)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar generador</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="deleteGeneradorExpedientes(item.genId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar generador</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="500px"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditGeneradores
        :genId="genId"
        @closeAndReload="closeAndReload"
      ></EditGeneradores>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditGeneradores from "@/components/modules/mesaEntrada/configuracion/EditGeneradores.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  components: { PageHeader, DeleteDialog, EditGeneradores, GoBackBtn },
  name: "GeneradoresExpedientes",
  
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    showDeleteModal: false,
    idToDelete: null,
    routeToGo: "ConfiguracionMesaEntrada",
    title: enums.titles.GENERADORES_DE_EXPEDIENTES,
    titleDelete: "¿Eliminar generador?",
    rules: rules,
    generadoresExpedientes: [],
    genId: null,
    headers: [
      {
        text: "Codigo",
        align: "start",
        value: "genCod"
      },
      {
        text: "Nombre",
        value: "genNom"
      },
      {
        text: "Tipo de generador",
        value: "tipoGenNom"
      },
      {
        text: "Calle",
        value: "genCalle"
      },
      {
        text: "Número",
        value: "genNro"
      },
      {
        text: "Localidad",
        value: "genLocNom"
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canGoToTiposGeneradores: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadGeneradoresExpedientes();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getGeneradoresDeExpedientes:
        "configuracionME/getGeneradoresDeExpedientes",
      deleteGeneradoresDeExpedientes:
        "configuracionME/deleteGeneradoresDeExpedientes",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_GENERADOR_EXPEDIENTES:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_GENERADOR_EXPEDIENTES:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_GENERADOR_EXPEDIENTES:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.GO_TO_TIPOS_GENERADORES:
            this.canGoToTiposGeneradores = true;
            break;
          default:
            break;
        }
      });
    },
    async loadGeneradoresExpedientes() {
      const data = await this.getGeneradoresDeExpedientes();
      this.generadoresExpedientes = data;
    },
    deleteGeneradorExpedientes(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteGeneradoresDeExpedientes({
        id: this.idToDelete
      });

      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadGeneradoresExpedientes();
      }
    },
    openModal(genId) {
      this.openModalEdit = true;
      this.genId = genId;
    },

    closeAndReload() {
      this.openModalEdit = false;
      this.loadGeneradoresExpedientes();
    },
    openTiposGeneradores() {
      this.$router.push({
        name: "TiposGeneradores"
      });
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
</style>

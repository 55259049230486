<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="generadores-form"
          form="generadores-form"
          id="generadores-form"
          @submit.prevent="saveGeneradores()"
        >
          <v-row class="align-top">
            <v-col cols="10" class="py-0">
              <v-autocomplete
                v-model="tipoGeneradorSelected"
                ref="tipoGenerador"
                :items="tiposGeneradores"
                item-text="value"
                item-value="id"
                label="Tipo de generador"
                :rules="rules.required"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="p-0 m-0 pt-1">
              <v-tooltip top v-if="canCreateTipoGen">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="openModalTipoGen" v-on="on" v-bind="attrs">
                    {{ addIcon }}
                  </v-icon>
                </template>
                <span>Nuevo tipo de generador</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="genCod"
                label="Código"
                dense
                outlined
                :rules="
                  rules.required.concat(
                    [rules.maxLength(genCod, 50)],
                    rules.requiredTrim(genCod)
                  )
                "
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="genNom"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(genNom, 100),
                    rules.requiredTrim(genNom)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="genCalle"
                label="Calle"
                dense
                outlined
                :rules="
                  rules.required.concat(
                    genCalle !== null
                      ? [
                          rules.maxLength(genCalle, 100),
                          rules.requiredTrim(genCalle)
                        ]
                      : []
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-space-between">
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="genNro"
                label="N°"
                dense
                outlined
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    genNro !== null ? [rules.maxLength(genNro, 50)] : []
                  )
                "
              ></v-text-field>
            </v-col>

            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="genPiso"
                label="Piso"
                dense
                outlined
                v-mask="'####'"
                :rules="genPiso !== null ? [rules.maxLength(genPiso, 50)] : []"
              ></v-text-field>
            </v-col>

            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="genDepto"
                label="Depto."
                dense
                outlined
                :rules="
                  genDepto !== null ? [rules.maxLength(genDepto, 50)] : []
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="barrioSelected"
                ref="barrio"
                :items="barrios"
                item-text="value"
                item-value="id"
                label="Barrio"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="localidadSelected"
                ref="localidad"
                :items="localidades"
                item-text="value"
                item-value="id"
                label="Localidad"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="genTelefono"
                label="Teléfono"
                type="text"
                dense
                outlined
                v-mask="'###############'"
                :rules="
                  genTelefono !== null ? [rules.maxLength(genTelefono, 50)] : []
                "
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="genCelular"
                label="Celular"
                type="text"
                dense
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="
                  genCelular !== null ? [rules.maxLength(genCelular, 50)] : []
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="genEmail"
                label="Email"
                dense
                outlined
                :rules="
                  genEmail !== null
                    ? rules.email.concat([rules.maxLength(genEmail, 100)])
                    : []
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="observaciones"
                label="Observaciones"
                dense
                outlined
                auto-grow
                rows="1"
                :rules="
                  observaciones !== null
                    ? [rules.maxLength(observaciones, 1000)]
                    : []
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="generadores-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalEditTipoGenerador"
        v-model="openModalEditTipoGenerador"
        max-width="450px"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditTipoGenerador
          :tipoGenId="tipoGenId"
          @closeAndReload="closeAndReload"
        >
        </EditTipoGenerador>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import EditTipoGenerador from "./EditTipoGenerador.vue";

export default {
  components: { EditTipoGenerador },
  name: "EditGeneradores",
  directives: { mask },
  props: ["genId"],
  data: () => ({
    openModalEditTipoGenerador: false,
    routeToGo: "tipos-generadores-expedientes",
    isFormValid: false,
    localidades: [],
    localidadSelected: null,
    barrios: [],
    tipoGenId: null,
    barrioSelected: null,
    tiposGeneradores: [],
    tipoGeneradorSelected: null,
    formEditTitle: "Editar generador",
    title: enums.titles.GENERADORES_DE_EXPEDIENTES,
    addIcon: enums.icons.CIRCLE_ADD_ITEM,
    rules: rules,
    canCreateTipoGenerador: null,
    expediente: {},
    genNom: null,
    genCod: null,
    genCalle: null,
    genNro: null,
    genPiso: null,
    genDepto: null,
    genTelefono: null,
    genCelular: null,
    genEmail: null,
    observaciones: null,
    allowedActions: null,
    canCreateTipoGen: false
  }),
  created() {
    if (this.genId != null) {
      this.setGenerador(this.genId);
    } else {
      this.newGeneradorExpediente();
    }
    this.setPermisos();
    this.setSelects();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTipoGeneradoresDeExpedientes:
        "configuracionME/getTipoGeneradoresDeExpedientes",
      getGeneradoresDeExpedientesById:
        "configuracionME/getGeneradoresDeExpedientesById",
      saveGeneradoresDeExpedientes:
        "configuracionME/saveGeneradoresDeExpedientes",
      getLocalidades: "afiliaciones/getLocalidades",
      getBarrios: "afiliaciones/getBarrios",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_TIPO_GENERADOR:
            this.canCreateTipoGen = true;
            break;
          default:
            break;
        }
      });
    },
    async setSelects() {
      const locResponse = await this.getLocalidades();
      const barriosResponse = await this.getBarrios();
      this.localidades = locResponse;
      this.barrios = barriosResponse;
      this.setTiposGeneradores();
    },
    async setTiposGeneradores() {
      const tipoGeneradorResponse = await this.getTipoGeneradoresDeExpedientes();
      this.tiposGeneradores = tipoGeneradorResponse;
    },
    openModalTipoGen() {
      this.openModalEditTipoGenerador = true;
    },
    async setGenerador() {
      const response = await this.getGeneradoresDeExpedientesById(this.genId);
      // VER ESTO

      this.genNom = response.genNom;
      this.tipoGeneradorSelected = response.tipoGenId;
      this.genCod = response.genCod;
      this.genCalle = response.genCalle;
      this.genNro = response.genNro;
      this.genPiso = response.genPiso;
      this.genDepto = response.genDepto;
      this.barrioSelected = response.genBarrioId;
      this.localidadSelected = response.genLocId;
      this.genTelefono = response.genTelefono;
      this.genCelular = response.genCelular;
      this.genEmail = response.genEmail;
      this.observaciones = response.observaciones;
    },
    async newGeneradorExpediente() {
      this.formEditTitle = "Nuevo generador";
    },
    async saveGeneradores() {
      const data = {
        genId: this.genId,
        tipoGenId: this.tipoGeneradorSelected,
        genCod: this.genCod,
        genNom: this.genNom,
        genCalle: this.genCalle,
        genNro: this.genNro,
        genPiso: this.genPiso,
        genDepto: this.genDepto,
        genBarrioId: this.barrioSelected,
        genLocId: this.localidadSelected,
        genTelefono: this.genTelefono,
        genCelular: this.genCelular,
        genEmail: this.genEmail,
        observaciones: this.observaciones
      };
      const res = await this.saveGeneradoresDeExpedientes(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    closeAndReload() {
      this.openModalEditTipoGenerador = false;
      this.setTiposGeneradores();
    }
  }
};
</script>

<style></style>
